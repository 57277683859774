<template>
  <div class="homepage">
    <v-container class="category-block-header">
      <!-- <category-title :category="category" /> -->
      <!-- <h4 class="primary--text">
        Scopri tutte le offerte dedicate ai tuoi clienti
      </h4> -->
      <div class="primary--text grey lighten-3 rounded-sm">
        <!-- <cart-info-list :isCart="true" /> -->
      </div>
    </v-container>
    <!-- <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
    />
    <category-block
      :target="category"
      position="position4"
      class="category-block category-block-4"
    />
    <category-block
      :target="category"
      position="position5"
      class="category-block category-block-5"
    />
    <category-block
      :target="category"
      position="position6"
      class="category-block category-block-6"
    />
    <category-block
      :target="category"
      position="position7"
      class="category-block category-block-7"
    />
    <category-block
      :target="category"
      position="position8"
      class="category-block category-block-8"
    />
    <category-block
      :target="category"
      position="position9"
      class="category-block category-block-9"
    />
    <category-block
      :target="category"
      position="position10"
      class="category-block category-block-10"
    /> -->
    <category-block
      v-for="(config, idx) in categoryBlockOrderedByPriority"
      :key="config.categoryBlockId"
      :config="config"
      :class="
        `category-block category-block-position-${idx} category-block-${config.priority}`
      "
    />

    <ebsn-meta
      :target="category"
      path="category_info.FOOTER_DESCRIPTION"
      tag="div"
      class="category-block-footer"
    />
  </div>
</template>

<style lang="scss">
.homepage {
  .banner-slider {
    .proposal-img {
      .v-image {
        max-height: 400px;
        @media #{map-get($display-breakpoints, 'xl-only')} {
          max-height: 600px;
        }
      }
    }
  }
}
</style>
<script>
import split from "lodash/split";
// // import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import category from "~/mixins/category";
import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapActions, mapGetters } from "vuex";
// import CartInfoList from "@/components/cart/CartInfoList.vue";
// import CategoryTitle from "@/components/category/CategoryTitle.vue";

export default {
  name: "Home",
  mixins: [category, deliveryReactive, login],
  components: {
    // CartInfoWrapper,
    // CategoryBlock,
    // CartInfoList,
    // CategoryTitle
  },
  data() {
    return {
      key: 1,
      categoryBlocks: [
        {
          categoryBlockId: 316,
          templateBlock: {
            codInt: "position2"
          },
          templateBlockType: {
            templateBlockTypeId: 1,
            modeName: "BannerSlider"
          },
          priority: 2,
          proposals: [
            {
              id: 26390,
              categoryProposalType: {
                name: "ProposalImage"
              },
              slug: "",
              priority: 12,
              name: "",
              descr: "I nostri servizi",
              content: "",
              img: "/img_layout/ws_banner1.jpg",
              imgAlt: "servizi_patronato",
              imgDescription: "servizi_patronato",

              categoryProposedId: -1,
              clickable: false,
              link: "/servizi-patronato",
              proposalUrl: "/servizi-patronato",
              proposalUrlType: "",
              metaData: {
                category_proposal_type: {
                  SHOW_BTN: true,
                  BTN_TEXT: "SCOPRI LE OFFERTE",
                  IMAGE_DESKTOP: "/img_layout/ws_banner1.jpg",
                  IMAGE_MOBILE: "/img_layout/ws_banner1.jpg"
                },
                icon: "servizi"
              }
            },
            {
              id: 2642,
              categoryProposalType: {
                name: "ProposalImage"
              },
              slug: "",
              priority: 14,
              name: "",
              descr: "Offerte Telefonia",
              content: "",
              img: "/img_layout/ws_banner2.jpg",
              imgAlt: "foto_telefonia",
              imgDescription: "foto_telefonia",
              categoryProposedId: -1,
              clickable: false,
              link: "/offerte-telefonia",
              proposalUrl: "/offerte-telefonia",
              proposalUrlType: "",
              metaData: {
                category_proposal_type: {
                  SHOW_BTN: true,
                  BTN_TEXT: "SCOPRI LE OFFERTE",
                  IMAGE_DESKTOP: "/img_layout/ws_banner2.jpg",
                  IMAGE_MOBILE: "/img_layout/ws_banner2.jpg"
                }
              }
            }
          ],
          metaData: {
            categoryblocktype_BannerSlider: {
              BACKGROUND_COLOR: "#ffffff",
              MODE: "ProposalImage",
              SHOW_ARROWS: false,
              SHOW_BULLETS: false,
              JSON_CONFIG: {
                slidesPerGroup: 1,
                spaceBetween: 20,
                watchOverflow: true,
                autoplay: true,
                breakpoints: {
                  "0": {
                    slidesPerView: 1
                  },
                  "600": {
                    slidesPerView: 1
                  },
                  "960": {
                    slidesPerView: 1
                  },
                  "1264": {
                    slidesPerView: 1
                  },
                  "1904": {
                    slidesPerView: 1
                  }
                }
              }
            }
          }
        },

        {
          categoryBlockId: 320,
          templateBlock: {
            codInt: "position4"
          },
          templateBlockType: {
            templateBlockTypeId: 1,
            modeName: "BannerList"
          },
          priority: 2,
          proposals: [
            {
              id: 26400,
              categoryProposalType: {
                name: "ProductCard"
              },
              slug: "",
              priority: 12,
              name:
                "Changed standard for PTO drive shafts: What needs to be considered?",
              descr:
                "Changed DIN EN 12965, in force in the EU as of the start of 2021, ensures more safety for PTO drive shafts As of the start of 2021, a changed European Union (EU) standard is in force. It applies to all PTO shafts brought into circulation in the EU that are installed in a main drive, along with their guards. The changes to EN 12965:2019 relate...",
              content: "",
              img: "/img_layout/pc_1.jpeg",
              imgAlt: "servizi_patronato",
              imgDescription: "servizi_patronato",

              categoryProposedId: -1,
              clickable: false,
              link: "/servizi-patronato",
              proposalUrl: "/servizi-patronato",
              proposalUrlType: "",
              metaData: {
                category_proposal_type: {
                  SHOW_BTN: true,
                  BTN_TEXT: "More Info",
                  IMAGE_DESKTOP: "/img_layout/pc_1.jpeg",
                  IMAGE_MOBILE: "/img_layout/pc_1.jpeg"
                }
              }
            },
            {
              id: 26401,
              categoryProposalType: {
                name: "ProductCard"
              },
              slug: "",
              priority: 12,
              name: "Lower coupling: Don't forget the “calotte check”!",
              descr:
                "With regular care and maintenance, connectors live longer – and safety re-mains ensured. This article discusses the calotte for the classic ball system lower coupling. The calotte is the hemispherical “cup” in the trailer coupling that is placed onto the ball mounted on the tractor in the classic ball system lower coupling. Lack of up-keep, dirt...",
              content: "",
              img: "/img_layout/pc_2.jpeg",
              imgAlt: "servizi_patronato",
              imgDescription: "servizi_patronato",

              categoryProposedId: -1,
              clickable: false,
              link: "/servizi-patronato",
              proposalUrl: "/servizi-patronato",
              proposalUrlType: "",
              metaData: {
                category_proposal_type: {
                  SHOW_BTN: true,
                  BTN_TEXT: "More Info",
                  IMAGE_DESKTOP: "/img_layout/pc_2.jpeg",
                  IMAGE_MOBILE: "/img_layout/pc_2.jpeg"
                }
              }
            },
            {
              id: 26402,
              categoryProposalType: {
                name: "ProductCard"
              },
              slug: "",
              priority: 12,
              name:
                "Merger of Walterscheid Powertrain Group and Comer Industries",
              descr:
                "The merger announced today of Walterscheid Powertrain Group, a leading provider of highly-engineered mission-critical powertrain systems and services for off-highway and industrial applications into Comer Industries S. p. A., a designer and manufacturer of advanced engineering systems and solutions, creates a new global leader in the agricultural and...",
              content: "",
              img: "/img_layout/pc_3.jpeg",
              imgAlt: "servizi_patronato",
              imgDescription: "servizi_patronato",

              categoryProposedId: -1,
              clickable: false,
              link: "/servizi-patronato",
              proposalUrl: "/servizi-patronato",
              proposalUrlType: "",
              metaData: {
                category_proposal_type: {
                  SHOW_BTN: true,
                  BTN_TEXT: "More Info",
                  IMAGE_DESKTOP: "/img_layout/pc_3.jpeg",
                  IMAGE_MOBILE: "/img_layout/pc_3.jpeg"
                }
              }
            }
          ],
          metaData: {
            categoryblocktype_BannerList: {
              TITLE: "Walterscheid News",
              BACKGROUND_COLOR: "#ffcc30",
              MODE: "ProductCard",
              SHOW_ARROWS: false,
              SHOW_BULLETS: false,
              JSON_CONFIG: {
                slidesPerGroup: 1,
                spaceBetween: 20,
                watchOverflow: true,
                autoplay: false,
                breakpoints: {
                  "0": {
                    slidesPerView: 3
                  },
                  "600": {
                    slidesPerView: 3
                  },
                  "960": {
                    slidesPerView: 3
                  },
                  "1264": {
                    slidesPerView: 3
                  },
                  "1904": {
                    slidesPerView: 3
                  }
                }
              }
            }
          }
        },

        {
          categoryBlockId: 318,
          templateBlock: {
            codInt: "position3"
          },
          templateBlockType: {
            templateBlockTypeId: 1,
            modeName: "BannerSlider"
          },
          priority: 2,
          proposals: [
            {
              id: 26390,
              categoryProposalType: {
                name: "ProposalCardResponsive"
              },
              slug: "",
              priority: 12,
              name: "",
              descr: "",
              content: "",
              img: "/img_layout/wal_pto_cat.jpg",
              imgAlt: "servizi_patronato",
              imgDescription: "servizi_patronato",

              categoryProposedId: -1,
              clickable: false,
              link: "/servizi-patronato",
              proposalUrl: "/servizi-patronato",
              proposalUrlType: "",
              metaData: {
                category_proposal_type: {
                  SHOW_BTN: true,
                  BTN_TEXT: "PTO Driveshafts",
                  IMAGE_DESKTOP: "/img_layout/wal_pto_cat.jpg",
                  IMAGE_MOBILE: "/img_layout/wal_pto_cat.jpg"
                }
              }
            },
            {
              id: 2643,
              categoryProposalType: {
                name: "ProposalCard"
              },
              slug: "",
              priority: 14,
              name: "",
              descr: "",
              content: "",
              img: "/img_layout/wal_tas_cat_2.jpg",
              imgAlt: "foto_telefonia",
              imgDescription: "foto_telefonia",
              categoryProposedId: -1,
              clickable: false,
              link: "/offerte-telefonia",
              proposalUrl: "/offerte-telefonia",
              proposalUrlType: "",
              metaData: {
                category_proposal_type: {
                  SHOW_BTN: true,
                  BTN_TEXT: "Tractor Attachment System",
                  IMAGE_DESKTOP: "/img_layout/wal_tas_cat_2.jpg",
                  IMAGE_MOBILE: "/img_layout/wal_tas_cat_2.jpg"
                }
              }
            },
            {
              id: 2644,
              categoryProposalType: {
                name: "ProposalCard"
              },
              slug: "",
              priority: 14,
              name: "",
              descr: "",
              content: "",
              img: "/img_layout/wal_anhaengetechnik_cat.jpg",
              imgAlt: "foto_telefonia",
              imgDescription: "foto_telefonia",
              categoryProposedId: -1,
              clickable: false,
              link: "/offerte-telefonia",
              proposalUrl: "/offerte-telefonia",
              proposalUrlType: "",
              metaData: {
                category_proposal_type: {
                  SHOW_BTN: true,
                  BTN_TEXT: "Hitch System",
                  IMAGE_DESKTOP: "/img_layout/wal_anhaengetechnik_cat.jpg",
                  IMAGE_MOBILE: "/img_layout/wal_anhaengetechnik_cat.jpg"
                }
              }
            },
            {
              id: 2645,
              categoryProposalType: {
                name: "ProposalCard"
              },
              slug: "",
              priority: 14,
              name: "",
              descr: "",
              content: "",
              img: "/img_layout/wal_parts_cat.jpg",
              imgAlt: "foto_telefonia",
              imgDescription: "foto_telefonia",
              categoryProposedId: -1,
              clickable: false,
              link: "/offerte-telefonia",
              proposalUrl: "/offerte-telefonia",
              proposalUrlType: "",
              metaData: {
                category_proposal_type: {
                  SHOW_BTN: true,
                  BTN_TEXT: "Spare Parts",
                  IMAGE_DESKTOP: "/img_layout/wal_parts_cat.jpg",
                  IMAGE_MOBILE: "/img_layout/wal_parts_cat.jpg"
                }
              }
            },
            {
              id: 2646,
              categoryProposalType: {
                name: "ProposalCard"
              },
              slug: "",
              priority: 14,
              name: "",
              descr: "",
              content: "",
              img: "/img_layout/wal_tools_cat.jpg",
              imgAlt: "foto_telefonia",
              imgDescription: "foto_telefonia",
              categoryProposedId: -1,
              clickable: false,
              link: "/offerte-telefonia",
              proposalUrl: "/offerte-telefonia",
              proposalUrlType: "",
              metaData: {
                category_proposal_type: {
                  SHOW_BTN: true,
                  BTN_TEXT: "Special Tools",
                  IMAGE_DESKTOP: "/img_layout/wal_tools_cat.jpg",
                  IMAGE_MOBILE: "/img_layout/wal_tools_cat.jpg"
                }
              }
            },
            {
              id: 2650,
              categoryProposalType: {
                name: "ProposalCard"
              },
              slug: "",
              priority: 14,
              name: "",
              descr: "",
              content: "",
              img: "/img_layout/wal_icvd_cat.jpg",
              imgAlt: "foto_telefonia",
              imgDescription: "foto_telefonia",
              categoryProposedId: -1,
              clickable: false,
              link: "/offerte-telefonia",
              proposalUrl: "/offerte-telefonia",
              proposalUrlType: "",
              metaData: {
                category_proposal_type: {
                  SHOW_BTN: true,
                  BTN_TEXT: "ICVD Gearboxes",
                  IMAGE_DESKTOP: "/img_layout/wal_icvd_cat.jpg",
                  IMAGE_MOBILE: "/img_layout/wal_icvd_cat.jpg"
                }
              }
            }
          ],
          metaData: {
            categoryblocktype_BannerSlider: {
              BACKGROUND_COLOR: "#565657",
              MODE: "ProposalCardResponsiveNew",
              SHOW_ARROWS: false,
              SHOW_BULLETS: false,
              TITLE: "Search for Products",
              JSON_CONFIG: {
                slidesPerGroup: 1,
                spaceBetween: 20,
                watchOverflow: true,
                autoplay: false,
                breakpoints: {
                  "0": {
                    slidesPerView: 4
                  },
                  "600": {
                    slidesPerView: 4
                  },
                  "960": {
                    slidesPerView: 4
                  },
                  "1264": {
                    slidesPerView: 4
                  },
                  "1904": {
                    slidesPerView: 4
                  }
                }
              }
            }
          }
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      resetFilters: "category/resetFilters"
    }),
    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });

      this.key += 1;
    },
    hideContainer(name) {
      //used to fix slider parent container visibility if no cards inside slider
      this.hidden[name] = true;
    },
    getCategoryBlockOrderedByPriority() {
      try {
        return this.categoryBlocks;
      } catch (err) {
        console.log(err);
        return this.categoryBlocks;
      }
    },
    comparePriority(obj1, obj2) {
      if (obj1.priority < obj2.priority) {
        return -1;
      }
      if (obj1.priority > obj2.priority) {
        return 1;
      }
      return 0;
    }
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    alert: {
      get() {
        return this.$store.state.custom.priceDisplayDisclaimer;
      },
      set(value) {
        this.$store.commit("custom/updatePriceDisplayDisclaimer", value);
      }
    },
    categoryBlockOrderedByPriority() {
      return this.getCategoryBlockOrderedByPriority();
    }
  },
  mounted() {
    global.EventBus.$on("login", () => {
      if (this.$vuetify.breakpoint.xs) {
        this.$vuetify.goTo(0, { duration: 0 });
      }
    });
  }
};
</script>
